import React from 'react';
import { Copyright } from './Copyright';
import { CompanyInfo } from './CompanyInfo';
import { LinkSection } from './LinkSection';
import { SocialLinks } from './SocialLinks';
import { HackerCommunity } from './HackerCommunity';
import image from '../../../assets/hero/footer.webp';

export const Footer = () => {
  const linkSections = [
    {
      title: 'Useful Links',
      links: [
        { text: 'Home', path: '/' },
        { text: 'About us', path: '/company/about' },
        { text: 'Events', path: '/events' },
        { text: 'Terms of service', path: '/support/contact' }
      ]
    },
    {
      title: 'Our Services',
      links: [
        { text: 'MSSP', path: '/services/mssp' },
        { text: 'Information Systems Audit', path: '/services/isaudit' },
        { text: 'Vulnerability Assessment', path: '/services/vapt' },
        { text: 'Penetration Testing', path: '/services/vapt' },
        { text: 'Digital Forensics Investigation', path: '/services/digital-forensics' },
        { text: 'Managed Security Services Program', path: '/services/mssp' },
        { text: 'Cybersecurity Maturity Program For SACCOs (CMP4S)', path: '/services/cmp4s' }
      ]
    }
  ];

  return (
    <footer
      className="bg-gradient-to-b from-gray-900 to-black text-gray-300 font-sans"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center"
      }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <HackerCommunity />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 py-12">
          <CompanyInfo />
          {linkSections.map((section, index) => (
            <LinkSection key={index} title={section.title} links={section.links} />
          ))}
          <SocialLinks />
        </div>
        <Copyright />
      </div>
    </footer>
  );
};
