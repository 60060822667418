import React from 'react';
import './loading.css'

export const LoadingScreen = ({ isLoading }) => {
  return (
    <div className="custom-page">
    <main className="custom-container">
      <div className="custom-item">
        <i className="custom-loader custom-loader-2"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-9"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-3"></i>
      </div>
      
      <div className="custom-item">
        <i className="custom-loader custom-loader-4"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-1"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-5"></i>
      </div>
      
      <div className="custom-item">
        <i className="custom-loader custom-loader-6"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-8"></i>
      </div>
      <div className="custom-item">
        <i className="custom-loader custom-loader-7"></i>
      </div>
    </main>
  </div>
  );
};

