import React from "react";
import { ChevronRight } from "lucide-react";

export const LinkSection = ({ title, links }) => (
  <div>
    <h4 className="text-lg font-semibold mb-4 text-white">{title}</h4>
    <ul className="space-y-2">
      {links.map((link, index) => (
        <li key={index} className="flex items-center group">
          <ChevronRight
            size={16}
            className="text-blue-500 mr-2 transition-transform group-hover:translate-x-1"
          />
          <a
            href={link.path}
            className="text-gray-400 hover:text-blue-400 transition-colors duration-200"
          >
            {link.text}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
