import React, { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MobileNavigation from './MobileNavigation';
import AuthButtons from './AuthButtons';
import { Menu, X } from 'lucide-react';
import { HaveBeenHacked } from './HaveBeenHacked';
import backgroundImage from "../../../assets/mobileView/mobilleBg.svg"

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setIsOpen]);

  return (
    <div ref={menuRef}>
      <button
        className="text-white hover:text-blue-300 focus:outline-none focus:text-blue-300"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
            className="fixed top-0 right-0 h-full bg-white shadow-xl overflow-y-auto z-40 w-3/4 sm:w-3/4"
             style ={{
              backgroundImage : `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center", 
             }}
          >
            <div className="flex justify-end p-4">
              <button
                className="text-gray-600 hover:text-gray-900 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                <X size={24} />
              </button>
            </div>
            <div className=" bg-blue-100 h-full">
              <MobileNavigation />
              <div className="py-12">
              <div className="py-8 px-4  bg-blue-950">
                <HaveBeenHacked />
              </div>
              <div className="px-4 py-8 bg-blue-950">
                <AuthButtons />
              </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MobileMenu;