import React from 'react';
import {Logo} from '../../../components/common/Header/Logo';
export const CompanyInfo = () => (
  <div className="space-y-4">
    <Logo/>
    <p className="text-sm text-gray-400">Cybersecurity For African Businesses</p>
    <address className="not-italic text-sm">
      <p>Dr.Lukwiya Road, Kiwatule</p>
      <p>kampala, Uganda</p>
      <p className="mt-2"><strong>Phone:</strong> +256 778 290 491</p>
      <p><strong>Email:</strong><a href="mailto:support@milimasecurity.com"> support@milimasecurity.com</a></p>
    </address>
  </div>
);