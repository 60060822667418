import React from 'react';
import { Send } from 'lucide-react';

// Newsletter Component
export const HackerCommunity = () => {
  const [email, setEmail] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Implement newsletter subscription logic
    console.log('Subscribing email:', email);
    setEmail('');
  };

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-800 rounded-lg shadow-inner my-8">
      <div className="max-w-xl mx-auto text-center">
        <h4 className="text-2xl font-bold mb-4 text-white">Join Our Hacker Community</h4>
        <p className="mb-6 text-gray-400">Join our active community of ethical hackers doing crazy stuff across Africa!</p>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="flex items-center max-w-md mx-auto bg-white rounded-full p-1">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full px-4 py-2 rounded-full focus:outline-none text-gray-900 transition-transform duration-200 ease-in-out focus:scale-105"
              required
            />
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <Send size={20} />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};