import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const MainNavigation = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredSubitem, setHoveredSubitem] = useState(null);
  const [fontSize, setFontSize] = useState('text-sm');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1536) {
        setFontSize('text-base');
      } else if (window.innerWidth >= 1280) {
        setFontSize('text-sm');
      } else {
        setFontSize('text-xs');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navItems = [
    {
      name: 'Products',
      link: '/products',
      subitems: [
        { name: 'Grey Armour', link: '/products/greyarmour' },
        { name: 'CSAT', link: '/products/csat' },
      ],
    },
    {
      name: 'Services',
      link: '/services',
      subitems: [
        { name: 'Cybersecurity Maturity Program for SACCOS', link: '/services/cmp4s' },
        { name: 'Digital Forensics Investigations', link: '/services/digital-forensics' },
        { name: 'Vulnerability Assessment and Pen Testing', link: '/services/vapt' },
        { name: 'Information Systems Audit', link: '/services/isaudit' },
        { name: 'MSSP', link: '/services/mssp' },
      ],
    },
    {
      name: 'Research',
      link: '/research',
      subitems: [
        { name: 'Africa Cybersecurity Report', link: '/research/cybersecurity_report' },
        { name: 'The Guardian Watch', link: '/research/guardian_watch' },
      ],
    },
    {
      name: 'Support',
      link: '/support',
      subitems: [
        { name: 'Help Resources', link: '/support/help_resources' },
        { 
          name: 'Contact Us', 
          link: '/support/contact',
          subitems: [
            { name: 'Book Demo', link: '/support/book-demo' },
            { name: 'Speak To Us', link: '/support/contact' },
          ]
        },
      ],
    },
    {
      name: 'Company',
      link: '/company',
      subitems: [
        { name: 'Who We Are', link: '/company/about' },
        { name: 'Board Of Advisors', link: '/company/board_of_advisors' },
        { name: 'Management Team', link: '/company/team' },
        { name: 'Careers', link: '/company/careers' },
      ],
    },
    {
      name: 'Events',
      link: '/events',
      subitems: [
        // { name: 'Events', link: '/events/upcoming' },
        { name: 'October 2024 Awareness Month', link: '/events/october-awareness-month',
          subitems: [
            { name: 'EastCon 2024 Cybersecurity Symposium', link: '/events/october-awareness-month/eastcon' },
          ]
        },
      ],
    },
  ];

  const dropdownVariants = {
    hidden: { 
      opacity: 0, 
      y: -5,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -5 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <nav className="hidden lg:block">
      <ul className="flex space-x-3 xl:space-x-5 items-center">
        {navItems.map((item, index) => (
          <React.Fragment key={item.name}>
            <li
              className="relative group"
              onMouseEnter={() => setHoveredItem(item.name)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <a href={item.link} className={`text-white font-medium hover:text-blue-300 transition duration-300 flex items-center hover:underline hover:decoration-4 ${fontSize}`}>
                {item.name}
                <svg
                  className={`ml-1 h-3 w-3 transition-transform duration-300 ease-out ${
                    hoveredItem === item.name ? '-rotate-180' : ''
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </a>
              <AnimatePresence>
                {hoveredItem === item.name && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={dropdownVariants}
                    className="absolute left-0 mt-2 w-64 bg-blue-950 bg-opacity-90 backdrop-filter backdrop-blur-lg border border-blue-950 shadow-lg py-2 z-10"
                  >
                    <ul>
                      {item.subitems.map((subitem) => (
                        <motion.li 
                          key={subitem.name} 
                          variants={itemVariants}
                          onMouseEnter={() => setHoveredSubitem(subitem.name)}
                          onMouseLeave={() => setHoveredSubitem(null)}
                          className="relative"
                        >
                          <a href={subitem.link}
                            className={`block px-6 py-2 ${fontSize} text-gray-300 hover:bg-blue-800 hover:text-white transition duration-200 hover:font-bold ${
                              subitem.subitems ? 'flex items-center justify-between' : ''
                            }`}
                          >
                            {subitem.name}
                            {subitem.subitems && (
                              <motion.svg
                                className="h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                initial={{ rotate: 90 }}
                                animate={{ rotate: hoveredSubitem === subitem.name ? 0 : 270 }}
                                transition={{ duration: 0.3 }}
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </motion.svg>
                            )}
                          </a>
                          {subitem.subitems && hoveredSubitem === subitem.name && (
                            <motion.div
                              initial="hidden"
                              animate="visible"
                              exit="hidden"
                              variants={dropdownVariants}
                              className="absolute left-full top-0 w-48 bg-blue-950 bg-opacity-90 backdrop-filter backdrop-blur-lg border border-blue-950 shadow-lg py-2 ml-1"
                            >
                              <ul>
                                {subitem.subitems.map((nestedItem) => (
                                  <motion.li key={nestedItem.name} variants={itemVariants}>
                                    <a href={nestedItem.link}
                                      className={`block px-4 py-2 ${fontSize} text-gray-300 hover:bg-blue-800 hover:text-white transition duration-200 hover:font-bold`}
                                    >
                                      {nestedItem.name}
                                    </a>
                                  </motion.li>
                                ))}
                              </ul>
                            </motion.div>
                          )}
                        </motion.li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </li>
            {index < navItems.length - 1 && (
              <span className="text-gray-500">|</span>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MainNavigation;