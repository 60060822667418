import React from 'react';

export const TopBanner = () => {
  return (
    <div className={`inset-x-0 top-0 bg-gradient-to-r from-blue-900 to-blue-800 text-white text-sm shadow-md transition-transform duration-300}`}>
      <div className="mx-auto max-w-7xl px-4 py-2 sm:px-6 lg:px-8">
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 justify-between items-center">
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                 stroke="currentColor" aria-hidden="true" className="mr-2 h-5 w-5 text-blue-300">
              <path strokeLinecap="round" strokeLinejoin="round"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z">
              </path>
            </svg>
            <span className="font-medium">Want To Try Our MSSP? </span>
            <a href="/support/book-demo" className="ml-2 px-3 py-1 hover:bg-blue-900 underline font-semibold transition duration-300 ease-in-out transform hover:scale-105">
              Book A Demo
            </a>
          </div>
          <div className="flex flex-wrap justify-center sm:justify-end items-center space-x-2">
            <span className="font-medium">Quick Contacts:</span>
            <a href="tel:+256778290491" className="flex items-center hover:text-blue-300 transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              +256 778 290 491
            </a>
            <span className="hidden sm:inline">|</span>
            <a href="mailto:support@milimasecurity.com" className="flex items-center hover:text-blue-300 transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              support@milimasecurity.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};