import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const MobileNavigation = () => {
  const [expandedItem, setExpandedItem] = useState(null);
  const [expandedSubitem, setExpandedSubitem] = useState(null);

  const navItems = [
    {
      name: 'Products',
      link: '/products',
      subitems: [
        { name: 'Grey Armour', link: '/products/greyarmour' },
        { name: 'CSAT', link: '/products/csat' },
      ],
    },
    {
      name: 'Services',
      link: '/services',
      subitems: [
        { name: 'Cybersecurity Maturity Program for SACCOS', link: '/services/cmp4s' },
        { name: 'Digital Forensics Investigations', link: '/services/digital-forensics' },
        { name: 'Vulnerability Assessment and Pen Testing', link: '/services/vapt' },
        { name: 'Information Systems Audit', link: '/services/isaudit' },
        { name: 'MSSP', link: '/services/mssp' },
      ],
    },
    {
      name: 'Research',
      link: '/research',
      subitems: [
        { name: 'Africa Cybersecurity Report', link: '/research/cybersecurity_report' },
        { name: 'The Guardian Watch', link: '/research/guardian_watch' },
      ],
    },
    {
      name: 'Support',
      link: '/support',
      subitems: [
        { name: 'Help Resources', link: '/support/help_resources' },
        { 
          name: 'Contact Us', 
          link: '/support/contact',
          subitems: [
            { name: 'Book Demo', link: '/support/book-demo' },
            { name: 'Speak To Us', link: '/support/contact' },
          ]
        },
      ],
    },
    {
      name: 'Company',
      link: '/company',
      subitems: [
        { name: 'Who We Are', link: '/company/about' },
        { name: 'Board Of Advisors', link: '/company/board_of_advisors' },
        { name: 'Management Team', link: '/company/team' },
        { name: 'Careers', link: '/company/careers' },
        
      ],
    },
    {
      name: 'Events',
      link: '/events',
      subitems: [
        // { name: 'Events', link: '/events/upcoming' },
        { name: 'October 2024 Awareness Month', link: '/events/october-awareness-month',
          subitems: [
            { name: 'EastCon 2024 Cybersecurity Symposium', link: '/events/october-awareness-month/eastcon' },
          ]
        },
      ],
    },
  ];

  const handleItemClick = (itemName) => {
    setExpandedItem(expandedItem === itemName ? null : itemName);
    setExpandedSubitem(null);
  };

  const handleSubitemClick = (subitemName) => {
    setExpandedSubitem(expandedSubitem === subitemName ? null : subitemName);
  };

  return (
    <nav className="py-4">
      <ul>
        {navItems.map((item) => (
          <li key={item.name} className="border-b border-gray-200 last:border-b-0">
            <button
              className="flex justify-between items-center w-full px-4 py-2 text-left text-gray-700 hover:bg-gray-100 focus:outline-none font-semibold hover:font-bold"
              onClick={() => handleItemClick(item.name)}
            >
              {item.name}
              <svg
                className={`h-4 w-4 transition-transform duration-200 ${
                  expandedItem === item.name ? 'transform rotate-180' : ''
                }`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <AnimatePresence>
              {expandedItem === item.name && (
                <motion.ul
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="bg-gray-50"
                >
                  {item.subitems.map((subitem) => (
                    <li key={subitem.name}>
                      {subitem.subitems ? (
                        <div>
                          <button
                            className="flex justify-between items-center w-full px-6 py-2 text-sm text-gray-600 hover:bg-gray-100 focus:outline-none hover:font-semibold"
                            onClick={() => handleSubitemClick(subitem.name)}
                          >
                            {subitem.name}
                            <svg
                              className={`h-4 w-4 transition-transform duration-200 ${
                                expandedSubitem === subitem.name ? 'transform rotate-180' : ''
                              }`}
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                          </button>
                          <AnimatePresence>
                            {expandedSubitem === subitem.name && (
                              <motion.ul
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: 'auto', opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.2 }}
                                className="bg-gray-100"
                              >
                                {subitem.subitems.map((nestedItem) => (
                                  <li key={nestedItem.name}>
                                    <a
                                      href={nestedItem.link}
                                      className="block px-8 py-2 text-sm text-gray-600 hover:bg-gray-200"
                                    >
                                      {nestedItem.name}
                                    </a>
                                  </li>
                                ))}
                              </motion.ul>
                            )}
                          </AnimatePresence>
                        </div>
                      ) : (
                        <a
                          href={subitem.link}
                          className="block px-6 py-2 text-sm text-gray-600 hover:bg-gray-100"
                        >
                          {subitem.name}
                        </a>
                      )}
                    </li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MobileNavigation;