import React from 'react';
import { AlertTriangle } from 'lucide-react';

export const HaveBeenHacked = () => {
  return (
    <a href="/have-been-hacked">
    <button className="
      bg-gradient-to-r from-blue-700 to-blue-800
      hover:from-blue-600 hover:to-blue-700
      text-white font-semibold
      px-2 py-1.5 sm:px-3 sm:py-2 md:px-4 md:py-2 lg:px-4 lg:py-2.5
      rounded-md shadow-md hover:shadow-lg
      transform hover:scale-105 transition-all duration-300
      flex items-center justify-center space-x-1 sm:space-x-2
      text-xs sm:text-sm md:text-base lg:text-sm
      whitespace-nowrap
    ">
      <AlertTriangle className="w-3 h-3 sm:w-4 sm:h-4 md:w-4 md:h-4 text-red-900" />
      <span>I have Been Hacked</span>
    </button>
    </a>
  );
};