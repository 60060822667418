import React from 'react';
export const SocialLinks = () => {
  const socialLinks = [
    { iconClass: "fa-brands fa-x-twitter fa-2xs", href: 'https://x.com/milimatechug', name: 'Twitter' },
    { iconClass: "fa-brands fa-facebook-f fa-2xs", href: 'https://www.facebook.com/milimatechnologies/', name: 'Facebook' },
    { iconClass: "fa-brands fa-instagram fa-2xs", href: 'https://www.instagram.com/milimatechug/', name: 'Instagram' },
    { iconClass: "fa-brands fa-linkedin-in fa-2xs", href: 'https://www.linkedin.com/company/milimasecurity/mycompany/', name: 'LinkedIn' },
    { iconClass: "fa-brands fa-github fa-2xs", href: 'https://github.com/', name: 'LinkedIn' },
    { iconClass: "fa-brands fa-tiktok fa-2xs", href: 'https://www.tiktok.com/@milimatechug', name: 'LinkedIn' }


  ];

  return (
    <div>
    <h4 className="text-lg font-semibold mb-4 text-white">Connect With Us</h4>
    <p className="mb-4 text-sm text-gray-400">Follow us on social media for the latest updates and insights.</p>
      <div className="flex flex-wrap gap-4 mt-8">
        {socialLinks.map((social, index) => (
          <a
            key={index}
            href={social.href}
            target="_blank"
            rel="noreferrer"
            className="flex items-center text-gray-400 hover:text-blue-400 transition-colors duration-200"
            aria-label={`Follow us on ${social.name}`}
          >
            <i className={`${social.iconClass} `} style={{ fontSize: '20px' }}></i>
            <span className="sr-only">{social.name}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

   