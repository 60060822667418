import React, { useState, useEffect } from 'react';
import { Logo } from './Logo';
import MainNavigation from './MainNavigation';
import MobileMenu from './MobileMenu';
import AuthButtons from './AuthButtons';
import { TopBanner } from './TopBanner';
import { HaveBeenHacked } from './HaveBeenHacked';

export const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
  const [showBanner, setShowBanner] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const isMobile = windowWidth < 1024;

  return (
    <header className="fixed top-0 left-0 right-0 z-50">
    {showBanner && <TopBanner />}
    <div className={`bg-blue-950 shadow-md transition-all duration-300 ${showBanner ? 'py-6' : 'py-8'}`}>
      <div className="container mx-auto px-2">
        <div className="flex justify-between items-center">
          <Logo />
          {isMobile ? (
            <MobileMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
          ) : (
            <div className="flex items-center space-x-6">
              <MainNavigation />
              <HaveBeenHacked/>
              <AuthButtons />
            </div>
          )}
        </div>
      </div>
    </div>
    </header>
  );
};

export default Header;