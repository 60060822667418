import React from 'react';
import logo from '../../../assets/logo/logo.svg'

export const Logo = () => {
  return (
    <a href="/" className="block">
    <div className="w-[10rem] h-auto sm:w-[10rem] sm:h-auto md:w-[10rem] md:h-auto lg:w-[8rem] lg:h-auto xl:w-[10rem] xl:h-auto transition-all duration-300 flex items-center justify-center">
      <img 
        src={logo} 
        alt="Milima Security Logo" 
        className="w-[10rem] h-auto max-h-full object-contain"
      />
    </div>
    </a>
  );
};
