import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MessageCircle, Send, User, ShieldCheck, X, Loader } from 'lucide-react';

export const CybersecurityChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

//   const addMessage = useCallback((type, content) => {
//     setMessages((prev) => [...prev, { type, content, timestamp: new Date().toISOString() }]);
//   }, []);

const addMessage = useCallback((type, content) => {
    setMessages((prev) => [...prev, { 
      type, 
      content: typeof content === 'object' ? content.text : content, 
      contactLink: content.contactLink,
      timestamp: new Date().toISOString() 
    }]);
  }, []);
  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    let greeting;
    if (hour < 12) greeting = "Good morning";
    else if (hour < 18) greeting = "Good afternoon";
    else greeting = "Good evening";
    return greeting;
  };
  const toggleChat = useCallback(() => {
    setIsOpen((prev) => !prev);
    if (!isOpen && messages.length === 0) {
      const greeting = getTimeBasedGreeting();
      addMessage('ai', `${greeting}! Welcome to Milima Security. How can we assist you with your cybersecurity needs today?`);
    }
  }, [isOpen, messages.length, addMessage]);

  const handleInputChange = useCallback((e) => setInput(e.target.value), []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (input.trim() === '' || isLoading) return;

    addMessage('user', input);
    setInput('');
    setIsLoading(true);
    setError(null);

    try {
      const aiResponse = await getAIResponse(input);
      addMessage('ai', aiResponse);
    } catch (err) {
      setError("Sorry, there was an error processing your request. Please try again.");
      console.error("Error in AI response:", err);
    } finally {
      setIsLoading(false);
    }
  }, [input, isLoading, addMessage]);

  const getAIResponse = async (userInput) => {
    await new Promise(resolve => setTimeout(resolve, 1000));

    const lowerInput = userInput.toLowerCase();
    const contactLinkText = "contact us";

    const createResponse = (text) => {
        return {
          text,
          contactLink: {
            text: contactLinkText,
            href: "/support/contact"
          }
        };
      };

    if (lowerInput.includes('mssp') || lowerInput.includes('managed security')) {
      return createResponse(`Our Managed Security Service Provider (MSSP) solutions offer 24/7 monitoring, threat detection, and incident response to keep your business safe from cyber threats. Would you like to know more about a specific aspect of our MSSP services?`);
    } else if (lowerInput.includes('vulnerability') || lowerInput.includes('assessment')){
      return createResponse(`Our Vulnerability Assessment service identifies and prioritizes security weaknesses in your systems. We use advanced tools and techniques to scan your infrastructure, applications, and networks, providing you with a comprehensive report of potential vulnerabilities and recommendations for remediation. This proactive approach helps you address security gaps before they can be exploited. Would you like to schedule a vulnerability assessment?`);
    } else if (lowerInput.includes('penetration') || lowerInput.includes('pentest')) {
      return createResponse(`Our Penetration Testing service simulates real-world attacks to test your defenses. Our certified ethical hackers attempt to exploit vulnerabilities in your systems, providing valuable insights into your security posture. This helps identify weaknesses that automated scans might miss and validates the effectiveness of your existing security measures. Interested in learning more about our penetration testing methodologies?`);
    } else if (lowerInput.includes('awareness') || lowerInput.includes('training')) {
      return createResponse(`Our Security Awareness Training educates your team on the latest cybersecurity best practices. We offer interactive online courses, simulated phishing exercises, and in-person workshops tailored to your organization's needs. This training helps create a security-conscious culture, reducing the risk of human error-related incidents. Would you like to see our training curriculum?`);
    } else if (lowerInput.includes('incident') || lowerInput.includes('response')) {
      return createResponse(`Our Incident Response Planning service helps you prepare for and respond to potential security incidents. We develop customized plans, conduct tabletop exercises, and provide 24/7 support in case of an actual incident. This ensures you're well-prepared to handle cyber threats swiftly and effectively, minimizing potential damage. Shall we discuss how to strengthen your incident response capabilities?`);
    } else if (lowerInput.includes('cloud')|| lowerInput.includes('security')) {
      return createResponse(`Our Cloud Security services ensure your cloud infrastructure is protected against threats. We offer cloud configuration reviews, continuous monitoring, and security automation for popular platforms like AWS, Azure, and Google Cloud. This helps maintain the confidentiality, integrity, and availability of your cloud-based assets. Would you like a cloud security assessment?`);
    } else if (lowerInput.includes('network') && lowerInput.includes('security')) {
      return createResponse(`Our Network Security services safeguard your network from unauthorized access and attacks. We implement firewalls, intrusion detection/prevention systems, and conduct regular network audits. This comprehensive approach helps protect your critical data and maintains the integrity of your network infrastructure. Interested in a network security consultation?`);
    } else if (lowerInput.includes('location') || lowerInput.includes('where')) {
      return createResponse(`We're based in Kampala, Uganda, but we serve clients throughout East Africa and beyond with our cutting-edge cybersecurity solutions. Our local expertise combined with global best practices allows us to provide tailored security services to businesses of all sizes in the region. Need more details about our coverage? Please`);
    } else if (lowerInput.includes('contact') || lowerInput.includes('address')) {
      return createResponse(`Our team is ready to answer your questions. Please`);
    } else if (lowerInput.includes('service')|| lowerInput.includes('offer')) {
      return createResponse(`We offer a wide range of cybersecurity services including vulnerability assessments, penetration testing, security awareness training, and incident response planning.`);
    }
    else {
      return createResponse(`Thank you for your question. Our team of cybersecurity experts would be happy to provide more detailed information about our services, including vulnerability assessments, penetration testing, security awareness training, incident response planning, cloud security, and network security. Please for personalized assistance. Is there a specific area of cybersecurity you'd like to discuss?`);
    }
  };
  

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <button
        onClick={toggleChat}
        className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium border rounded-full w-12 h-12 bg-blue-900 hover:bg-blue-700 text-white m-0 cursor-pointer p-0 normal-case leading-5 z-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        type="button"
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        aria-label="Open chat"
      >
        <MessageCircle size={30} />
      </button>

      {isOpen && (
        <div 
          role="dialog"
          aria-modal="true"
          aria-labelledby="chat-title"
          className="fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-white p-4 rounded-lg border border-gray-200 w-[320px] h-[480px] shadow-lg z-50 flex flex-col"
        >
          <div className="flex justify-between items-center pb-4">
            <div>
              <h2 id="chat-title" className="font-semibold text-lg tracking-tight">Milima Security Chat</h2>
              <p className="text-xs text-gray-500 leading-3">Your cybersecurity Company in Uganda</p>
            </div>
            <button 
              onClick={toggleChat}
              className="text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 rounded-full p-1"
              aria-label="Close chat"
            >
              <X size={20} />
            </button>
          </div>

          <div className="flex-grow overflow-y-auto pr-2 mb-4">
          {messages.map((msg, index) => (
          <div key={index} className={`flex gap-2 my-2 text-gray-600 text-xs flex-1 ${msg.type === 'user' ? 'justify-end' : ''}`}>
            <span className="relative flex shrink-0 overflow-hidden rounded-full w-6 h-6">
              <div className={`rounded-full ${msg.type === 'user' ? 'bg-blue-100' : 'bg-gray-100'} border p-1`}>
                {msg.type === 'user' ? <User size={16} /> : <ShieldCheck size={16} />}
              </div>
            </span>
            <p className={`leading-relaxed ${msg.type === 'user' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100'} rounded-lg py-2 px-3 max-w-[75%]`}>
              <span className="block font-bold text-gray-700">{msg.type === 'user' ? 'You' : 'Milima Security'} </span>
              {msg.content}
              {msg.contactLink && (
                <>
                  {' '}
                  <a href={msg.contactLink.href} className="text-blue-600 hover:underline">
                    {msg.contactLink.text}
                  </a>
                </>
              )}
            </p>
            </div>
            ))}
            {error && (
              <div className="text-red-500 text-xs mt-2">{error}</div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSubmit} className="flex items-center mt-auto">
            <input
              ref={inputRef}
              className="flex h-8 w-full rounded-md border border-gray-200 px-3 py-2 text-xs placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-50 text-gray-900"
              placeholder="Type your message"
              value={input}
              onChange={handleInputChange}
              disabled={isLoading}
              aria-label="Chat input"
            />
            <button
              type="submit"
              className="ml-2 inline-flex items-center justify-center rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 h-8 px-3 py-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              disabled={isLoading}
              aria-label="Send message"
            >
              {isLoading ? <Loader size={16} className="animate-spin" /> : <Send size={16} />}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

