import React from 'react';

const AuthButtons = () => {
  return (
    <div className="flex space-x-4">
      <a
        href="https://csat.milimasecurity.com/" target="_blank" rel="noreferrer"
        className="px-2 py-2 text-sm font-medium text-blue-200 border border-blue-900 rounded-md hover:bg-blue-900 hover:text-white transition duration-300 ease-in-out"
      >
        Log In
      </a>
      <a
        href="https://csat.milimasecurity.com/auth/register/" target="_blank" rel="noreferrer"
        className="px-2 py-2 text-sm font-medium text-white bg-blue-900 rounded-md hover:bg-blue-800 transition duration-300 ease-in-out"
      >
        Sign up
      </a>
    </div>
  );
};

export default AuthButtons;